<template>
  <a-modal :class="$style.container" v-model="isShow" :title="(this.form.id ? '编辑' : '新增')+'分类规格'" @ok="determine" @cancel="cancel">
    <a-form-model ref="form" :rules="goyRules" :class="$style.form__ct" class="al-form"
                  :label-col="{ style: 'width: 150px' }" :wrapper-col="{ style: 'width: 300px'  }" layout="inline"
                  :model="form"
    style="height: 300px ">
      <a-row type="flex" justify="space-between">
        <a-form-model-item label="规格分类名称:" prop="name">
          <a-input v-model="form.name" type="string" placeholder="请输入"/>
        </a-form-model-item>
      </a-row>
      <br/>
      <a-row type="flex" justify="space-between" style="margin-top: 10px">
        <a-form-model-item label="规格">
          <a-select
            allowClear
            v-model="form.goSpecificationsCopy"
            mode="tags"
            style="width: 100%"
            placeholder="请输入"
            @change="handleChangeSpecification"
            aria-selected="true"
          >
          </a-select>
        </a-form-model-item>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  data () {
    return {
      loading1: false,
      disabledStatus: false,
      goySpecificationVOList: [], // 列表
      goSpecifications: {
        id: '',
        name: ''
      },
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      app: {}, // 应用信息
      form: {
        id: '',
        name: '',
        goSpecifications: []
      },
      tagSpecification: [],
      tags: [],
      inputVisible: false,
      inputValue: '',
      inputVisible2: false,
      inputValue2: '',
      goyRules: {
        name: [{
          required: true,
          message: '名称不能为空',
          trigger: 'change'
        }]
      }
    }
  },
  created () {
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    handleChangeSpecification (e) {
      let e2 = []
      if (!this.form.goSpecifications) { this.form.goSpecifications = [] }
      this.form.goSpecifications.forEach(element => {
        e2.push(element.name)
      })

      let toAddVal = []
      e.forEach(element => {
        if (!e2.includes(element))toAddVal.push(element)
      })
      let toRemoveVal = []
      e2.forEach(element => {
        if (!e.includes(element))toRemoveVal.push(element)
      })

      toAddVal.forEach((item) => {
        this.form.goSpecifications.push({
          name: item
        })
      })
      toRemoveVal.forEach((item) => {
        this.form.goSpecifications.forEach((element, index) => {
          if (item === element.name) {
            this.form.goSpecifications.splice(index, 1)
          }
        })
      })
    },
    // 设置表单
    setForm (form = {}) {
      console.log(form, '传过来的数据')
      const { id, name, item, goSpecificationStr } = { ...form }
      this.form = {
        id: id, // ID
        name: name, // 名称
        goSpecificationsCopy: goSpecificationStr ? goSpecificationStr.split(',') : undefined,
        goSpecifications: item || []
      }
      console.log('数据：', form)
    },
    cancel () {
      this.$refs.form.resetFields()
      this.setForm()
    },
    // 提交保存
    async determine (e) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          let api = 'addSpec'
          if (params.id) {
            api = 'updateSpec'
          }
          await this.$store.dispatch('http', {
            api,
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: (params.id ? '编辑' : '新增') + '规格成功~' })
          this.isShow = false
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
