<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <!-- <a-button @click="showModal" class="add-bt" type="primary">新增</a-button> -->
      <a-button type="primary"  @click="$refs.specificationDialog.setForm();$refs.specificationDialog.isShow = true">新增</a-button>
    </Breadcrumb>
     <a-form
      ref="formRef"
      layout="inline"
      :model="form"
    >
      <a-form-item>
        <a-input v-model="form.name" placeholder="名称" />
      </a-form-item>

      <a-form-item>
        <a-button  @click="queryList" type="primary" html-type="submit">查询</a-button>
        <span style="margin-left:20px;"> </span>
        <a-button @click="resetForm('form')">重置</a-button>
      </a-form-item>
    </a-form>
    <br/>
    <a-table :data-source="list"
             bordered
             :rowKey="record => record.id"
             @change="currentChange"
             :columns="columns"
             :pagination="pagination"
             :childrenColumnName="Y">
      <div slot="action1"
           slot-scope="scope">
        <div>{{scope.name}}</div>
      </div>
      <div slot="action2"
           slot-scope="scope">
        <span style="margin: 7px" v-for="(item,index) in scope.item" :key="index" :value="item.id">
          {{item.name}}
        </span>
      </div>
      <span slot="action"
            slot-scope="text,record">
              <a @click="$refs.specificationDialog.setForm(record);$refs.specificationDialog.isShow = true">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm
          title="确认要删除吗?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="confirm(record.id)"
          @cancel="cancel"
        >
          <a href="#">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <add-goy-specification ref="specificationDialog" @save="reloadList"></add-goy-specification>
  </div>
</template>

<script>
import addGoySpecification from './addOrEdit'
import Breadcrumb from '@/components/breadcrumb.vue'
export default {
  components: { addGoySpecification, Breadcrumb },
  data () {
    return {
      breadList: [
        {
          name: '规格设置',
          url: ''
        }
      ],
      Y: '', // 暂时不配置树形结构
      visible: false,
      doctorImag: '',
      form: {
        tenantId: undefined,
        id: '',
        name: '',
        sort: '',
        idPerent: '',
        iconUrl: '',
        goySpecificationsId: '',
        goyPropertysId: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'change' }
        ]
      },
      columns: [
        { title: '规格名称', width: 80, key: 'name', scopedSlots: { customRender: 'action1' } },
        { title: '规格值', width: 250, key: 'item', scopedSlots: { customRender: 'action2' } },
        { title: '操作', key: 'operation', width: 200, scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      showList: [],
      classList: [],
      total: 0,
      list: [],
      titleList: [],
      certificationStatusList: [],
      selectedRowKeys: [],
      hiddenProperty: '',
      visibilityChangeEvent: '',
      onVisibilityChange: null,
      isQueryLoading: false
    }
  },
  created () {
    this.reloadList()
  },
  mounted () {
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
    cancel (values) {
      console.log(values)
    },
    // 是否确认弹窗口  方法
    confirm (id) {
      this.delColumn(id)
    },
    // 删除
    async delColumn (id) {
      const res = await this.$store.dispatch('http', {
        api: 'deleteSpec',
        query: { id: id }
      })
      if (res) {
        this.$store.dispatch('showToast', { type: 'success', message: '删除成功' })
        this.reloadList()
      }
    },

    moment,
    onChange1 (date, dateString) {
      console.log('dateString: ', dateString)
      this.form.startTime = dateString[0] || ''
      this.form.endTime = dateString[1] || ''
    },
    handleSearch1 (value) {
      console.log('搜索的', value)
      this.yaoDianList(value)
    },
    // 重置表单
    resetForm () {
      this.form = {
        name: ''
      }
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
        this.selectedRowKeys = []
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    async loadList (callback = () => { }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'getSpecList',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      this.list = records
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log(val)
      if (this.pagination.current === val.current) {
        this.pagination.current = 1
      } else {
        this.pagination.current = val.current
      }
      this.pagination.pageSize = val.pageSize
      this.loadList()
    },
    // 选中事件
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    zoom (scope) {
      this.visible = true
      this.doctorImag = scope
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
